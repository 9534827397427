<template>
  <Modal :options="{width: '30vw',type:'warning',esc:false}">
    <div class="title">{{ configuracion.title }}</div>
    <div v-html="configuracion.body" class="body"></div>
    <div class="footer">
      <div class="row">
        <div class="col-sm-12 text-right">
          <button class="btn btn-success mr-2" @click.prevent="configuracion.accept_callback">{{ configuracion.button_accept }}</button>
          <button class="btn btn-danger mr-2" @click.prevent="configuracion.reject_callback">{{ configuracion.button_reject }}</button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal';

export default {
  components: {
    Modal
  },
  props: {
    config: {
      type: Object,
      required: true,
      default() {
        return {
          title: 'Modal confirmación'
        }
      }
    }
  },
  computed: {
    configuracion() {
      let config = this.config;

      if (!config.title)
        config.title = 'Modal de confirmación';

      if (!config.body)
        config.body = '<p>Cuerpo de confirmación</p>';

      if (!config.button_accept)
        config.button_accept = 'Aceptar';

      if (!config.button_reject)
        config.button_reject = 'Cancelar';

      if (typeof config.accept_callback != 'function')
        config.accept_callback = () => {
          this.$helper.showMessage('Exito!','Confirmación aceptada','success','alert');
        } 

      if (typeof config.reject_callback != 'function')
        config.reject_callback = () => {
          this.$helper.showMessage('Error!','Confirmación rechazada','error','alert');
        }
      
      return config;
    }
  }
}
</script>